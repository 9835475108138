import {
  KEYWORD,
  MANUFACTURER,
  MODEL,
  PRODUCT_TYPE,
  ENVIRONMENT,
  LOCATION,
  COLLECTION_NAME,
  SPECIFIC_MATCHES_OF_VALUES_IN_THE_DATABASE,
  FILTERS_MATCH_CONFIG,
  VERIFIED,
} from './searchingFilters';
const INPUT_DROPDOWN_COMPONENT = 'InputDropdown';
const INPUT_TEXT_COMPONENT = 'InputText';
const INPUT_BOOL_COMPONENT = 'InputBool';
const DEFAULT_FIELD_TO_RENDERING = [
  {
    field: 'Keyword',
    dataType: 'text',
    component: INPUT_TEXT_COMPONENT,
    value: '',
    alias: KEYWORD,
  },
  {
    field: 'Verified',
    dataType: 'boolean',
    value: true,
    items: [true, false],
    component: INPUT_BOOL_COMPONENT,
    alias: VERIFIED,
    specificFieldInDatabase: SPECIFIC_MATCHES_OF_VALUES_IN_THE_DATABASE[VERIFIED],
    match: FILTERS_MATCH_CONFIG[VERIFIED],
  },
  {
    field: 'Manufacturer',
    dataType: 'array',
    component: INPUT_DROPDOWN_COMPONENT,
    value: '',
    items: [],
    autofocus: false,
    alias: MANUFACTURER,
    specificFieldInDatabase: SPECIFIC_MATCHES_OF_VALUES_IN_THE_DATABASE[MANUFACTURER],
    match: FILTERS_MATCH_CONFIG[MANUFACTURER],
  },
  {
    field: 'Model',
    dataType: 'text',
    component: INPUT_TEXT_COMPONENT,
    value: '',
    alias: MODEL,
    specificFieldInDatabase: SPECIFIC_MATCHES_OF_VALUES_IN_THE_DATABASE[MODEL],
    match: FILTERS_MATCH_CONFIG[MODEL],
  },
  {
    field: 'Product Type',
    dataType: 'array',
    component: INPUT_DROPDOWN_COMPONENT,
    value: '',
    items: [],
    alias: PRODUCT_TYPE,
    autofocus: false,
    specificFieldInDatabase: SPECIFIC_MATCHES_OF_VALUES_IN_THE_DATABASE[PRODUCT_TYPE],
    match: FILTERS_MATCH_CONFIG[PRODUCT_TYPE],
  },
  {
    field: 'Environment',
    dataType: 'array',
    component: INPUT_DROPDOWN_COMPONENT,
    value: '',
    items: [],
    alias: ENVIRONMENT,
    autofocus: false,
    specificFieldInDatabase: SPECIFIC_MATCHES_OF_VALUES_IN_THE_DATABASE[ENVIRONMENT],
    match: FILTERS_MATCH_CONFIG[ENVIRONMENT],
  },
  {
    field: 'Location',
    dataType: 'text',
    component: INPUT_TEXT_COMPONENT,
    value: '',
    alias: LOCATION,
    match: FILTERS_MATCH_CONFIG[LOCATION],
  },
  {
    field: 'Collection name',
    dataType: 'text',
    option: 'write text',
    component: INPUT_TEXT_COMPONENT,
    alias: COLLECTION_NAME,
    match: FILTERS_MATCH_CONFIG[COLLECTION_NAME],
  },
];
export default DEFAULT_FIELD_TO_RENDERING;