<template>
  <div class="search-filter white">
    <div class="d-flex gap-6">
      <v-combobox
        ref="comboBox"
        v-click-outside="onClickOutside"
        :disabled="disabledInput"
        :filter="onFilter"
        :items="lodash.cloneDeep(items)"
        :menu-props="{
          contentClass: 'py-15 search-filter__menu',
          maxHeight: 'none',
          nudgeBottom: 1,
          value: selectedMenu,
        }"
        :placeholder="placeholder + '...'"
        :search-input.sync="search"
        :value="selectedFiltersToSearch"
        class="v-text-field--outlined__rectangle mx-auto sourcery__search-bar search-bar"
        color="black"
        data-test="collections_search_field"
        item-text="field"
        item-value="index"
        attach
        deletable-chips
        hide-details
        multiple
        return-object
        single-line
        @click.stop
        @click:append="onKeydown(true)"
        @click:clear="$emit('search', [])"
        @click:prepend-inner="$emit('search', selectedFiltersToSearch)"
        @input="onAddItem"
        @keydown.40="onKeydown"
        @keydown.enter="onPressEnter"
        @keydown.delete="onKeydownDelete"
        @update:list-index="updateSelectedListIndex">
        <template #append>
          <div
            class="nav-svg-icon icon-search-filter pointer"
            :class="{ 'blue': selectedMenu }"
            @click="selectedMenu = !selectedMenu" />
        </template>
        <template #selection="{ item, index }">
          <component
            :is="item.component"
            v-bind="$attrs"
            :name="item.field"
            :item-value="item.value"
            :items="item.items"
            :alias="item.alias"
            :autofocus="item.autofocus"
            :index="index"
            @update="onUpdate(index, $event)"
            @remove="onRemove(index, $event)" />
        </template>
        <template #item="{ item }">
          <v-list-item
            class="pa-0"
            @click="onAddItem([...selectedFiltersToSearch, item ], !!search)">
            {{ item.field }} <span v-if="search"> : {{ search }}</span>
          </v-list-item>
        </template>
      </v-combobox>
    </div>
  </div>
</template>
<script>
import {
  mapGetters, mapMutations,
} from 'vuex';
import {
  cloneDeep, isEmpty,
} from 'lodash';

import {
  PRODUCT_AND_COLLECTION_FILTERS,
  VERIFIED,
} from '@/constants/searching/searchingFilters';
import defaultItems from '@/constants/searching/searchingFieldsToRendering';

import ScheduleCells from '@/services/graphql/scheduleCells';

import {
  EventBus,
  START_NEW_COMMUNITY_SEARCH,
} from '@/utils/eventBus';

export default {
  name: 'SearchFilter',
  components: {
    InputText: () => import('@/components/SearchFilter/SearchFilterInputText'),
    InputDropdown: () => import ('@/components/SearchFilter/SearchFilterInputDropdown'),
    InputBool: () => import ('@/components/SearchFilter/SearchFilterInputBoolean'),
  },
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    queryFilters: {
      type: Object,
      default: null,
    },
    filtersToUse: {
      type: Array,
      default: () => (PRODUCT_AND_COLLECTION_FILTERS),
    },
    updateFromProps: {
      type: Boolean,
      default: false,
    },
    testValue: {
      type: String,
      default: '',
    },
    isUseWrapperMargin: {
      type: Boolean,
      default: false,
    },
    disabledInput: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    selectedListIndex: -1,
    selectedMenu: false,
    defaultItems,
    selectedFiltersToSearch: [],
    search: '',
  }),
  computed: {
    ...mapGetters(['getVerifiedSearch']),
    items() {
      const { defaultItems: items, filtersToUse: filters } = this;
      return items.filter(({ alias }) => filters.includes(alias));
    },
    itemsLength() {
      const { items } = this;
      return items.length;
    },
    getKeywordFilter() {
      return this.items.find(({ field }) => field == 'Keyword');
    },
    getLastSelectedItemIndex() {
      const { selectedFiltersToSearch: arr, selectedFilterLength: leng } = this;
      if (!leng) return 0;
      const { index = 0 } = arr[leng - 1] ?? {
      };
      return index;
    },
    getDropdownFilters() {
      return this.items.filter(({ dataType }) => dataType == 'array');
    },
    selectedFilterLength() {
      const { selectedFiltersToSearch: arr } = this;
      if (!arr) return 0;
      return arr.length;
    },
  },
  watch: {
    queryFilters: {
      handler() {
        this.setSelectedAccordingToQueryFilters();
      },
    },
    getVerifiedSearch(nVal, oVal) {
      if (nVal === oVal) return;

      // add verified search
      if (nVal) {
        const verifiedItem = this.defaultItems.find((item) => item.alias === VERIFIED);
        verifiedItem.value = true;
        this.onAddItem([...this.selectedFiltersToSearch, verifiedItem], !!this.search);
      } else {
        // remove verified filter
        const index = this.selectedFiltersToSearch.findIndex(item => item.alias === VERIFIED);
        if (index != -1) {
          this.selectedFiltersToSearch.splice(index, 1);
          this.$emit('search', this.selectedFiltersToSearch);
        }
      }
    },
    selectedMenu(nVal) {
      nVal
        ? document.documentElement.classList.add('custom-layout')
        : document.documentElement.classList.remove('custom-layout');
    },
  },
  async created() {
    await this.getDropdownValues();
    this.setDropdownValuesToSelected();
    this.setSelectedAccordingToQueryFilters();
  },
  mounted() {
    EventBus.$on(START_NEW_COMMUNITY_SEARCH, () => {
      this.startBlankVerifiedSearch();
    });
  },
  destroyed() {
    EventBus.$off(START_NEW_COMMUNITY_SEARCH);
  },
  methods: {
    ...mapMutations(['setVerifiedSearch']),
    async getDropdownValues() {
      this.defaultItems = await Promise.all(this.items.map(async item => {
        const { field, dataType } = item;
        if (dataType !== 'array') return item;
        try {
          const { data } = await ScheduleCells.getDropdownValues({
            col: field,
          });
          const { fixedValues: items = [] } = data?.response ?? {
          };
          return {
            ...item,
            items,
          };
        } catch (err) {
          console.log('err getDropdownValues', err);
          return {
            ...item,
          };
        }
      }));
    },
    keepOnlyLatestIsVerified(items) {
      if (!items || items.length <= 1) return items;
      const last = items[items.length - 1];
      if (last.alias !== VERIFIED) return items;

      return items.filter((item, index) => {
        return item.alias !== VERIFIED || index === items.length - 1;
      });
    },
    onAddItem(items, update = false) {
      this.selectedMenu = false;
      const lastItem = items.at(-1);

      items = items.filter(item => item?.field);
      items = this.keepOnlyLatestIsVerified(items);

      if (items.length > this.selectedFilterLength) {
        items[items.length - 1] = {
          index: this.selectedFilterLength + 1,
          ...lastItem,
        };
      }
      this.selectedFiltersToSearch = items;

      if (update) {
        this.onUpdate(this.getLastSelectedItemIndex - 1, {
          value: this.search,
        });
        this.search = '';
      } else if (lastItem.alias === VERIFIED) {
        // search automatically when verified is added
        this.setVerifiedSearch(lastItem.value);
        this.$emit('search', this.selectedFiltersToSearch);
      }
      return this;
    },
    onClickOutside() {
      this.selectedMenu = false;
    },
    onFilter(item, queryText) {
      if (item.dataType == 'text') {
        return true;
      }
      if (item.dataType == 'array') {
        return item.items.find(item => {
          return item.toLowerCase().includes(queryText.toLowerCase());
        });
      }
      return false;
    },
    onKeydown(toggleMode = false) {
      this.$refs.comboBox.$el.querySelector('input').focus();
      this.selectedMenu = toggleMode ? !this.selectedMenu : true;
    },
    async onKeydownDelete() {
      if (!this.selectedFilterLength || this.search) {
        return;
      }
      await this.$nextTick();
      this.$emit('search', this.selectedFiltersToSearch);
    },
    async onPressEnter() {
      if (this.selectedListIndex !== -1) {
        return;
      }
      if (!this.search) {
        await this.$nextTick();
        this.$emit('search', this.selectedFiltersToSearch);
        return;
      }
      this.onAddItem([
        ...this.selectedFiltersToSearch,
        this.getKeywordFilter,
      ], true);
    },
    onRemove(index, search = false) {
      const removedItem = this.selectedFiltersToSearch.splice(index, 1)[0];

      if (removedItem.alias === VERIFIED) this.setVerifiedSearch(false);

      if (search) this.$emit('search', this.selectedFiltersToSearch);

      return this;
    },
    onUpdate(index, { value, final = false }) {
      if (isNaN(index)) {
        return;
      }
      const item = this.selectedFiltersToSearch[index];
      this.$set(item, 'value', value);
      if (item.dataType == 'array' && !final) {
        this.$set(item, 'autofocus', true);
        return this;
      }
      this.$emit('search', this.selectedFiltersToSearch);
      return this;
    },
    setDropdownValuesToSelected() {
      const mappedFilters = (this.selectedFiltersToSearch || []).map(filter => {
        const { items = [] } = this.getDropdownFilters.find(item => item.field == filter.field) ?? {
        };
        if (filter.dataType == 'array') {
          return {
            ...filter,
            items,
          };
        }
        return filter;
      });
      this.selectedFiltersToSearch = mappedFilters;
    },
    setSelectedAccordingToQueryFilters() {
      if (isEmpty(this.queryFilters) || !this.updateFromProps) {
        return;
      }
      this.selectedFiltersToSearch = Object.keys(this.queryFilters).reduce((acc = [], filterName) => {
        const filter = cloneDeep(this.items.find(({ alias }) => alias.toLowerCase() == filterName.toLowerCase()));
        // keep verified search in sync
        if (filter.alias === VERIFIED) this.setVerifiedSearch(true);

        let values = this.queryFilters[filterName];
        if (!Array.isArray(values)) {
          values = [values];
        }
        values.forEach(value => {
          acc.push({
            ...filter,
            value,
          });
        });
        return acc;
      }, []);
    },
    async startBlankVerifiedSearch() {
      const verifiedItem = this.defaultItems.find((item) => item.alias === VERIFIED);
      if (verifiedItem) {
        verifiedItem.value = true;
        this.selectedFiltersToSearch = [verifiedItem];
      }
      await this.$nextTick();
      this.$emit('search', this.selectedFiltersToSearch);
    },
    updateSelectedListIndex(index) {
      this.selectedListIndex = index;
      return this;
    },
  },
};
</script>
<style lang="scss" scoped>
.search-filter {
  ::v-deep &__menu {
    height: calc(100vh - 180px);
    padding: 0 84px;
    overflow: hidden;
    background: var(--v-lightGrey-base);
    box-shadow: none;

    .v-sheet {
      align-items: flex-start;
      background: inherit;
      display: flex;
      flex-direction: column;
      gap: 36px;
      padding: 0;
      height: 100%;
      overflow-y: auto;
    }
  }
}
.search-bar {
  ::v-deep .v-select__slot {
    input {
      flex: 1 1 0;
      min-width: 24px;
    }
    .v-chip {
      max-width: 90%;
    }
  }
}
</style>