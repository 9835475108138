var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"search-filter white"},[_c('div',{staticClass:"d-flex gap-6"},[_c('v-combobox',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}],ref:"comboBox",staticClass:"v-text-field--outlined__rectangle mx-auto sourcery__search-bar search-bar",attrs:{"disabled":_vm.disabledInput,"filter":_vm.onFilter,"items":_vm.lodash.cloneDeep(_vm.items),"menu-props":{
        contentClass: 'py-15 search-filter__menu',
        maxHeight: 'none',
        nudgeBottom: 1,
        value: _vm.selectedMenu,
      },"placeholder":_vm.placeholder + '...',"search-input":_vm.search,"value":_vm.selectedFiltersToSearch,"color":"black","data-test":"collections_search_field","item-text":"field","item-value":"index","attach":"","deletable-chips":"","hide-details":"","multiple":"","return-object":"","single-line":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"click":function($event){$event.stopPropagation();},"click:append":function($event){return _vm.onKeydown(true)},"click:clear":function($event){return _vm.$emit('search', [])},"click:prepend-inner":function($event){return _vm.$emit('search', _vm.selectedFiltersToSearch)},"input":_vm.onAddItem,"keydown":[function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==40){ return null; }return _vm.onKeydown.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onPressEnter.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"delete",[8,46],$event.key,["Backspace","Delete","Del"])){ return null; }return _vm.onKeydownDelete.apply(null, arguments)}],"update:list-index":_vm.updateSelectedListIndex},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticClass:"nav-svg-icon icon-search-filter pointer",class:{ 'blue': _vm.selectedMenu },on:{"click":function($event){_vm.selectedMenu = !_vm.selectedMenu}}})]},proxy:true},{key:"selection",fn:function(ref){
      var item = ref.item;
      var index = ref.index;
return [_c(item.component,_vm._b({tag:"component",attrs:{"name":item.field,"item-value":item.value,"items":item.items,"alias":item.alias,"autofocus":item.autofocus,"index":index},on:{"update":function($event){return _vm.onUpdate(index, $event)},"remove":function($event){return _vm.onRemove(index, $event)}}},'component',_vm.$attrs,false))]}},{key:"item",fn:function(ref){
      var item = ref.item;
return [_c('v-list-item',{staticClass:"pa-0",on:{"click":function($event){return _vm.onAddItem(_vm.selectedFiltersToSearch.concat( [item] ), !!_vm.search)}}},[_vm._v(" "+_vm._s(item.field)+" "),(_vm.search)?_c('span',[_vm._v(" : "+_vm._s(_vm.search))]):_vm._e()])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }